body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.separator {
  width: 85px;
  background-color: #fedd89;
  height: 2px;
  border: none;
}

.SlideIn-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transition: all 0.2s linear;
}

.SlideOut-appear {
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  opacity: 0;
}

.SlideOut-appear.SlideOut-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideOut-enter {
  opacity: 0;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
}
.SlideOut-enter.SlideOut-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideOut-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideOut-leave.SlideOut-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transition: all 0.2s linear;
}

@media print {
  #appHeader, #appFooter, #eanInputForm, #qrCodePreviewActions {
    display: none;
  }

  #qrCodeContainer {
    transform: rotate(270deg);
    box-shadow: none;
  }
}
